/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import 'bootstrap/dist/css/bootstrap.min.css'
import "../css/custom.css"

import MayaDiazLogo from "../images/maya-diaz-studio-logo.svg"
import { Container, Image } from 'react-bootstrap';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>

      <Container className="white max-width">
        <p className="text-center">
          <Image src={MayaDiazLogo} alt={data.site.siteMetadata.title} className=" logo-max-width ml-5 mr-5 pt-4" fluid />
        </p>
        <p className="text-center">(415) 456-6800</p>
        
        <main className="p-2 p-md-3">{children}</main>
        <footer className="pt-4 text-center pb-4">
        <p>Maya Diaz Studio © {new Date().getFullYear()}</p>
        <p>Site designed by <a href="https://nautilusdesigns.com">Nautilus Designs</a></p>         
        </footer>
      </Container>

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
